export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

export enum HTTP_STATUS_CODE {
  SUCCESS = '200',
  BAD_REQUEST = '400',
  NOT_FOUND = '404',
  AUTHORIZATION = '401',
  FORBIDDEN = '403',
  UNKNOWN = '520'
}

export enum HTTP_STATUS_RESPONSE_KEY {
  SUCCESS = '000000',
  BAD_REQUEST = '000400',
  NOT_FOUND = '000404',
  AUTHORIZATION = '000401',
  FORBIDDEN = '000403',
  UNKNOWN = '000500'
}

export enum ENDPOINT {
  ROOT = '/api',
  LOGIN = '/auth/login',
  LOGOUT = '/auth/logout',
  CREATE_PASSWORD = '/auth/create-new-password',
  FORGOT_PASSWORD = '/auth/forgot-password',
  CHANGE_PASSWORD = '/auth/create-new-password',
  CHANGE_ACCOUNT_PASSWORD = '/user/change-password',
  REFRESH_TOKEN = '/auth/refresh-token',
  GET_USER_PROFILE = '/user/profile',
  UPDATE_USER_PROFILE = '/user/update-profile',
  ACTIVE_ACCOUNT = '/user-mgt/account/activate',

  // Account management
  GET_ACCOUNT_LIST = '/system-mgt/account/view',
  GET_ACCOUNT_DETAIL = '/system-mgt/view/:id',
  CREATE_ACCOUNT = '/system-mgt/account/create',
  UPDATE_ACCOUNT = '/system-mgt/account/update/:id',
  GET_LIST_ROLE = '/user-permission/roles',
  GET_LIST_PERMISSION = '/user-permission/list',
  UPDATE_LIST_PERMISSION = 'user-permission/update',
  GET_LIST_USER_ASSIGN = 'user/view/list',
  GET_NEW_LINK_ACTIVE_ACCOUNT = '/system-mgt/account/resent-active-link',
  GET_USER_ASSIGN_BY_EMAIL = '/user/email',

  // Tenant
  GET_TENANT_LIST = '/tenant/view',
  GET_TEANANT_DASHBOARD = '/tenant/dashboard',
  DELETE_TENANT = 'tenant/delete/:tenantId',
  CREATE_TENANT = '/tenant/create',
  DETAIL_TENANT = 'tenant/view/:id',
  UPDATE_TENANT = 'tenant/update/:id',
  ASSIGN_PLANT = '/user-manage/assign/plant-owner',
  //Tenant
  GET_LIST_TENANT = 'tenant/view',
  GET_LIST_TENANT_TREE = '/tenant/list-tenant-tree',
  GET_VALID_NAME_TENANT = '/tenant/check-name',
  GET_TENANT_PLANTS = '/tenant/plants',
  REMOVE_PLANT_TENANT = '/tenant/remove/:id/plant',

  //Plant
  GET_PLANT_LIST = '/plant/view',
  GET_PLANT_ADD_TENANT = '/location/view-no-permissions',
  DELETE_PLANT = 'location/delete/:plantId',
  SERVICE_PLANT = 'plant/service/:id',
  DETAIL_PLANT = 'plant/view/:id',
  UPDATE_PLANT = 'plant/update/:id',
  CREATE_PLANT = 'plant/create',
  CREATE_LOAD_MONITOR = 'plant/create-load-monitor',
  CHECK_EXISTING_PLANT_NAME = '/plant/name',
  GET_DEVICE_TYPE = '/plant/devices',
  DOWNLOAD_CERTIFICATE = 'plant/download/:id',
  GET_USER_ASSIGN_OWNER_BY_EMAIL = 'plant/owner',
  GET_USER_ASSIGN_VIEWER_BY_EMAIL = 'plant/viewer',


  //ems
  GET_EMS_LIST = '/ems/list',
  ADD_EMS = '/ems/add',
  DELETE_EMS = 'ems/delete/:id',
  REMOVE_EMS = 'ems/remove/:id',
  GET_EMS_ADD_LOCATION = '/ems/list-add-to-location',
  CREATE_EMS = '/ems/create',
  GET_EMS_DETAIL = '/ems/detail/:id',
  UPDATE_EMS = '/ems/update/:id',
  DOWNLOAD_EMS = '/ems/download',

  //dashboard
  GET_OVERVIEW_EMS = '/dashboard/statistic/ems',
  GET_OVERVIEW_CELL_DRIVER = '/dashboard/statistic/cell-driver',
  GET_OVERVIEW_CELL = '/dashboard/statistic/cell',
  GET_OVERVIEW_PLANT = '/dashboard/plant',
  GET_METRICS_PLANT = '/dashboard/metrics',
  GET_TENANT_OVERVIEW = '/dashboard/tenant/:id',
  GET_OVERVIEW_SERVICE = 'dashboard/ems/:id/services',
  GET_OVERVIEW_DICTIONARY = 'command/dictionary',

  SETTING_DICTIONARY = 'command/setting-dictionary',

  //command logs
  GET_COMMAND_LOGS = '/command/view',

  // alarm
  GET_ALARM_LIST = '/alarm/view',
  DETAIL_ALARM = 'alarm/view/:id',
  UPDATE_ALARM = 'alarm/update/:id',

  // user activity logs
  GET_USER_ACTIVITY_LOGS_LIST = '/activity-log/list',

  //command-action
  COMMAND_EMS_STOP = '/command/ems/stop',
  COMMAND_EMS_CLEAR_ALL_FLAGS = '/command/ems/clear-all-flags',
  COMMAND_EMS_GET_URL = '/command/generate-upload-url/:fileName',
  COMMAND_EMS_SOFT_RESET = '/command/ems/soft-reset',
  COMMAND_EMS_CUSTOM_IPC = '/command/custom-ipc',
  COMMAND_EMS_CUSTOM_IPC_RESPONSE = '/command/custom-ipc-response',
  COMMAND_HUB_STOP = '/command/hub/stop',
  COMMAND_EMS_START = '/command/ems/start',
  COMMAND_HUB_START = '/command/hub/start',
  SET_EMS_POINT = '/command/ems/set-points',
  SET_HUB_POINT = '/command/hub/set-points',
  COMMAND_ALGORITHM = '/command/algorithm',
  COMMAND_SETTINGS = '/command/settings',
  UPDATE_FIRMWARE = '/command/update-firmware',
  REMOTE = '/command/remote',
  SET_COMMAND = '/command/set-of-commands',
  SET_EMS_SOC = '/command/ems/set-soc',
  SET_HUB_SOC = '/command/hub/set-soc',
  DISABLE = '/command/disable',
  ENABLE = '/command/enable',
  UPDATE_SOFTWARE = '/command/update-software',
  REFRESH_COMMAND = '/command/refresh/command-data/:id',
  REFRESH_SERVICE = '/command/refresh/ems/services/:id',
  REFRESH_SERVICE_SETTING = 'command/refresh/ems/service-setting/:id',
  //notification
  GET_LIST_NOTIFICATION = 'notification/view',
  UPDATE_NOTIFICATION = '/notification/update'
}
