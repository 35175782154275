const emsEntity = {
  title: {
    createEms: 'Create EMS',
    updateEms: 'Update EMS',
    emsDetails: 'EMS Details'
  },

  label: {
    emsInfo: 'EMS Info',
    emsName: 'EMS Name',
    emsId: 'EMS ID',
    tenantId: 'Tenant ID'
  },

  downloadCertAndKeysModal: {
    description:
      'Download and install the certificate and key files to your device so that it can connect security to AWS IoT.',
    deviceCert: 'Device certificate',
    keyFiles: 'Key files',
    keyFilesDESC: 'The key files are unique to this certificate.',
    publicKeyFile: 'Public key file',
    privateKeyFile: 'Private key file',
    rootCA: 'Root CA certificates',
    rootCADesc:
      'Download the root CA certificate file that corresponds to the type of data endpoint and cipher suite you’re using.',
    amazonServices: 'Amazon trust services endpoint',
    supplementaryFile: 'Supplementary file',
    howToConnectToIoTCoreFromEMS: 'How to connect to IoT Core from EMS',
    downloadAllAsTar: 'Download All as Tar'
  },

  associatedAssets: {
    company: 'Company',
    deviceType: 'Device Type',
    capacity: 'Capacity (kW/kWh)'
  },

  placeholder: {
    search:
      'Search by EMS Name, EMS ID, Tenant, Plant, Location, Location Owner'
  },

  button: {
    createEms: 'Create EMS',
    deleteEms: 'Delete EMS',
    updateEms: 'Update EMS',
    downloadFiles: 'Download Files',
    downloadCertAndKeys: 'Download certificates and keys',
    downloadNewCertAndKeys: 'Download new certificates and keys',
    download: 'Download',
    downloaded: 'Downloaded'
  }
};

export default emsEntity;
